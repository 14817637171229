import React from "react";

const Home = () => {
    return (
        <div className="home">
            <h1>MY OFFICE</h1>
            <h2>Recording & Transcription Services</h2>
            <hr></hr>

            <p>
                The importance of using an independent contractor for the purpose of recording and transcribing cannot be emphasized
                enough in our increasingly demanding legal environment. The advantages thereof include:
            </p>

            <ul>
                <li>Confidentiality</li>
                <li>An impartial, unbiased and accurate recording and transcription of proceedings</li>
                <li>Prevention of future disputes</li>
                <li>Professional expertise</li>
                <li>Transcribing format in accordance with High Court rules and regulations</li>
            </ul>
            <hr></hr>

            <p>
                A further advantage of our service is that it can be executed at any venue convenient to our clients. Taking into
                consideration the increasing cost of fuel and other expenses we are able to now deliver an additional option of 
                “self-recording” by our clients, which can then be sent to us for transcription or storage. As we have the appropriate 
                transcription and conversion software we can adapt the voice files and deliver the services as required.
            </p>
            <hr></hr>
            <p>
                <em>
                    My Office consists of a competent team of recording specialists, minute-takers, typists and proofreaders who process
                    and deliver our service in the following way: We make use of state-of-the-art digital recording equipment – ensuring
                    high quality recordings, which in turn ensures an accurate transcription.
                </em>
            </p>
        </div>
    );
}

export default Home;