import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Pages/HomePage";
import About from "./Pages/AboutPage";
import AreasOfPractice from "./Pages/AreasOfPracticePage";
import OurTeam from "./Pages/OurTeamPage";
import Contact from "./Pages/ContactPage";
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route exact path='/' element={< Home />}></Route>
                <Route exact path='/about' element={< About />}></Route>
                <Route exact path='/areas-of-practice' element={< AreasOfPractice />}></Route>
                <Route exact path='/team' element={< OurTeam />}></Route>
                <Route exact path='/contact' element={< Contact />}></Route>
            </Routes>
            <Footer />
        </Router>        
    );
}

export default App;