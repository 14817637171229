import React from "react";

const AreasOfPractice = () => {
    return (
        <div className="areas-of-practice">
            <h1>Our Areas of Practice</h1>
            <hr></hr>
            <p>
                We render professional services in both the public and private sector and this includes the recording, 
                transcription and general secretarial services pertaining to the following:
            </p>

            <ul>
                <li>Disciplinary Hearings</li>
                <li>Court Proceedings</li>
                <li>CCMA Hearings</li>
                <li>Insolvency Hearings</li>
                <li>Annual General Meetings</li>
                <li>Labour Related Disputes</li>
                <li>Commissions of Inquiry</li>
                <li>Arbitrations</li>
                <li>Any other important meetings, which demand record capturing</li>
            </ul>
            <hr></hr>

            <h2>PREVIOUS AND EXISTING CLIENTS:</h2>

            <ul>
                <li>Tsogo Sun Group (Including Southern Sun, Garden Court and Intercontinental Hotels)</li>
                <li>Department of Health</li>
                <li>PSCBC (Bargaining Council)</li>
                <li>Jali Commission of Inquiry</li>
                <li>Zaheer Attorneys (Pretoria)</li>
                <li>Frank Naidoo Attorneys (Pretoria)</li>
                <li>Barnard Incorporated Attorneys</li>
                <li>De Villiers & Du Plessis (SEESA – Pretoria)</li>
                <li>Labour Court (Johannesburg) (Bloemfontein) (Kimberley)</li>
                <li>Independent Electoral Commission</li>
                <li>Senior Advocates (Pretoria) (Durban) (Pietermaritzburg)</li>
                <li>South African Police Services</li>
                <li>Department of Defence</li>
                <li>Modimolle & Pietermaritzburg Municipalities</li>
                <li>Private Individuals - Professors - Lecturers - etc.</li>
            </ul>
        </div>
    );
}

export default AreasOfPractice;