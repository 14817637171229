import React, { useEffect, useState } from "react";
import logo from "../assets/MO-logo-tagline.jpg";
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import { Collapse } from "@mui/material";

const Header = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const linksVisible = useMediaQuery("(min-width:1064px)");

    const handleClick = (event) => {
        setIsExpanded(!isExpanded)
        event.preventDefault();
    }

    useEffect(() => {
        if (linksVisible) {
            setIsExpanded(false);
        }
    }, [linksVisible]);

    const renderNav = () => {
        if (linksVisible) {
            return (
                <div className="links">
                    <Link className="nav-link" to="/">Home</Link>
                    <Link className="nav-link" to="/about">About</Link>
                    <Link className="nav-link" to="/areas-of-practice">Areas of Practice</Link>
                    <Link className="nav-link" to="/team">Our Team</Link>
                    <Link className="nav-link" to="/contact">Contact Us</Link>
                </div>
            );
        }
        else {
            return (
                <div className="menu-btn">
                    <MenuIcon className="menu-icon" onClick={handleClick} sx={{ fontSize: 40, color: "white" }} />
                </div>                 
            );
        }
    }

    return (
        <div className="header">
            <div className="nav-wrapper">
                <div className="logo">
                    <img src={logo} alt={logo} width="50%"></img>
                </div>

                {renderNav()}
            </div>
            
            <div className="links-collapsed">
                <Collapse in={isExpanded}>
                    <Link className="nav-link" to="/">Home</Link>
                    <Link className="nav-link" to="/about">About</Link>
                    <Link className="nav-link" to="/areas-of-practice">Areas of Practice</Link>
                    <Link className="nav-link" to="/team">Our Team</Link>
                    <Link className="nav-link" to="/contact">Contact Us</Link>
                </Collapse>
            </div>
        </div>
    );
}

export default Header;