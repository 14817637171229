import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <h5>Johan and Elsa Naudè</h5>
            <div className="footer-info-container">
                <div className="footer-info">
                    <p>635 Christaller Street</p>
                    <p>Moreletapark</p>
                    <p>Pretoria</p>
                    <p>South Africa</p>
                </div>
                <div className="footer-info">
                    <p>Johan : 082 501 8389</p>
                    <p>Elsa : 082 468 0722</p>
                    <p>Email : info@myoff.co.za</p>
                </div> 
            </div>              
        </div>  
    );
}

export default Footer;