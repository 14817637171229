import React from "react";

const About = () => {
    return (
        <div className="about">
            <h1>About Us</h1>
            <hr></hr>
            <p>
                My Office is an independent service, which boasts experience in the field of both recording and transcription 
                since 1990. My Office consists of a competent team of recording specialists, minute-takers, typists and 
                proofreaders who process and deliver our service in the following way: We make use of state-of-the-art digital 
                recording equipment – ensuring high quality recordings, which in turn ensures an accurate transcription.
            </p>
        </div>
    );
}

export default About;