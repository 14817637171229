import React from "react";
import johanPhoto from "../../assets/Johan_photo.jpg";
import elsaPhoto from "../../assets/Elsa_photo.jpg";

const OurTeam = () => {
    return (
        <div className="our-team">
            <h1>Our Team</h1>
            <hr></hr>
            <div className="team-members-container">
                <div className="photo">
                    <img src={johanPhoto} alt={johanPhoto} width="400px"></img>
                    <h5>Johan Naudè</h5>
                </div>
                <div className="photo">
                    <img src={elsaPhoto} alt={elsaPhoto} width="400px"></img>
                    <h5>Elsa Naudè</h5>
                </div>
            </div>
        </div>
    );
}

export default OurTeam;