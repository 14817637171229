import React, { useState } from "react";
import emailjs from "emailjs-com";
import Success from "../SuccessMessage";

const Contact = () => {

    const [emailContent, setEmailContent] = useState({firstName: "", lastName: "", email: "", message: ""});
    const [errorMessage, setErrorMessage] = useState(null);
    const [messageSuccess, setMessageSuccess] = useState(false);

    const serviceId = process.env.REACT_APP_SERVICEID;
    const templateId = process.env.REACT_APP_TEMPLATEID;
    const userId = process.env.REACT_APP_USERID;

    const sendEmail = (name, email, message) => {
        emailjs.send(serviceId, templateId, { name, email, message }, userId);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEmailContent((prevContent) => {
            return (
                {
                    ...prevContent,
                    [name]: value
                }
            );
        })
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const renderErrorMessage = () => {
        return (
            <div className="submit-message" style={{ "color": "orangered"}}>
                {errorMessage}
            </div>
        );
    }

    const goBackToForm = () => {
        setMessageSuccess(false);
    }

    const renderForm = () => {
        if (!messageSuccess) {
            return (
                <form>
                    <label htmlFor="name" style={{ "display": "block" }}>
                        Name
                    </label>
                    <input onChange={handleChange} value={emailContent.firstName} className="name-input first-name" id="first-name" name="firstName" type="text" placeholder="First name*"></input>
                    <input onChange={handleChange} value={emailContent.lastName} className="name-input last-name" id="last-name" name="lastName" type="text" placeholder="Last name"></input>

                    <label htmlFor="email" style={{ "display": "block" }}>
                        Email*
                    </label>
                    <input onChange={handleChange} value={emailContent.email} type="text" name="email" id="email"></input>

                    <label htmlFor="message" style={{ "display": "block" }}>
                        Message*
                    </label>
                    <textarea onChange={handleChange} value={emailContent.message} rows={6} name="message" id="message"></textarea>
                    {renderErrorMessage()}
                    <div className="send-btn-wrapper">
                        <button onClick={handleSubmit} className="send-btn" type="submit">
                            Send
                        </button>
                    </div>
                </form>
            );
        }
        else {
            return <Success backButtonClick={goBackToForm}/>
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (emailContent.firstName !== "" && emailContent.email !== "" && emailContent.message !== "") {

            if (isValidEmail(emailContent.email)) {

                setMessageSuccess(true);
                sendEmail(`${emailContent.firstName} ${emailContent.lastName}`, emailContent.email, emailContent.message);
                setEmailContent({
                    firstName: "",
                    lastName: "", 
                    email: "", 
                    message: ""
                });
                setErrorMessage(null);
            }
            else {
                setErrorMessage("Please provide a valid email address.");
            }
        }
        else {
            setErrorMessage("Please fill in all of the required (*) fields.");
        }
    };

    return (
        <div className="contact">
            <h1>Contact Us</h1>
            <hr></hr>
            <div className="form-wrapper">
                {renderForm()}
            </div>
        </div>
    );
}

export default Contact;