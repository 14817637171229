import React from "react";

const Success = (props) => {

    const handleClick = () => {
        props.backButtonClick();
    }

    return (
        <div className="success-message">
            <p>
                Thank you for your message! We will get back to you as soon as possible.
            </p>

            <button className="back-btn" onClick={handleClick}>Go back</button>
        </div>
    );
}

export default Success;